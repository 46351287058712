import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../LocalizedLink'
import styles from './index.module.css'

class Slogan extends React.Component {
  render() {
    return this.context.intl.locale === 'en' ? <div className={styles.slogan_img + ' ' + styles.en} /> :
      <div className={styles.slogan_img + ' ' + styles.zh} />
  }
}

Slogan.contextTypes = {
  intl: PropTypes.object
};

const PartnerBanner = () => (
  <div className={styles.banner}>
    {/* <h1 className={styles.title}>
      <FormattedMessage id="title1" />
      <span className={styles.line}> - </span>
      <FormattedMessage id="title2" />
    </h1> */}
    <Slogan />
    {/* <div style={{ fontSize: 10, fontWeight: 100 }}>Large-scale commericial</div> */}
    {/* <h1 className={styles.slogan}>
      <FormattedMessage id="slogan.gift" />
      <span className={styles.point}> · </span> 
      <FormattedMessage id="slogan.free" />
    </h1>
    <div className={styles.focus}>
      <FormattedMessage id="slogan.give" /> <span style={{ color: '#F8E71C' }}>500 USDT </span>
      <FormattedMessage id="slogan.deposit" />
    </div>
    <div className={styles.focus}>
      <FormattedMessage id="slogan.firstCome" />
    </div> */}
    {/* <p className={styles.desc}>
      <FormattedMessage id="desc.onestop" />
    </p> */}
    <div className={styles.action}>
      <a href="http://atlaspio.mikecrm.com/q3rTS59" target="blank">
        <button className={styles.try}>
          <FormattedMessage id="applyForTrial" />
        </button>
      </a>
      <LocalizedLink to="/product">
        <button className={styles.more}>
          <FormattedMessage id="more" />
        </button>
      </LocalizedLink>
    </div>
  </div>
)

export default PartnerBanner
