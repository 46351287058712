import PropTypes from 'prop-types'
import React from 'react'
// import Banner from '../components/Banner'
import Banner from '../components/PartnerBanner'
import Illutration from '../components/Illustration'
import Institution from '../components/Institution'
import Layout from '../components/NewLayout'
import Media from '../components/Media'

const IndexPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <Banner />
    <Illutration />
    <Institution />
    <Media />
  </Layout>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default IndexPage
