import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { addLocaleData, IntlProvider } from 'react-intl'
// Locale data
import enData from 'react-intl/locale-data/en'
import zhData from 'react-intl/locale-data/zh'
// Messages
import en from '../../i18n/en.json'
import zh from '../../i18n/zh.json'
// Components
import Header from '../Header'
import MyPoint from '../MyPoint/index.js'
import Footer from '../NewFooter'
// Style
import './modern-normalize.css'
import styles from './style.module.css'

const messages = { en, zh }

addLocaleData([...enData, ...zhData])

const Layout = ({ locale, location, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      {locale === 'en' ? (
        <Helmet>
          <title>Atlas Protocol - Blockchain interactive advertising & marketing</title>
          <meta
            name="description"
            content="Atlas Protocol - Blockchain interactive advertising & marketing, Achieve your marketing goals with "
          />
          <meta
            name="keywords"
            content="Atlas,AtlasProtocol,AtlasInteractive,星途协议,星图协议,星途协议ATP,星途互动,ATP,BlockchainAdTech,Blockchain,AdTech,Tokenized,Marketing,区块链互动营销,区块链广告营销,通证化营销,区块链广告,区块链广告技术,区块链互动广告营销"
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>星途协议ATP - 区块链互动广告营销</title>
          <meta
            name="description"
            content="星途协议-区块链互动广告营销,用透明、卓越的区块链互动广告技术实现您的营销目标,打破信息孤岛,获得更多优质关注."
          />
          <meta
            name="keywords"
            content="Atlas,AtlasProtocol,AtlasInteractive,星途协议,星图协议,星途协议ATP,星途互动,ATP,BlockchainAdTech,Blockchain,AdTech,Tokenized,Marketing,区块链互动营销,区块链广告营销,通证化营销,区块链广告,区块链广告技术,区块链互动广告营销"
          />
        </Helmet>
      )}
      <Header location={location} />
      <div className={styles.content}>{children}</div>
      <MyPoint />
      <Footer />
    </Fragment>
  </IntlProvider>
)

export default Layout
