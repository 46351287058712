import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './index.module.css'
import logo1 from './interaction@2x.png'
import logo2 from './incentive@2x.png'
import logo3 from './intelligence@2x.png'
import advantage1 from './advantage1@2x.png'
import advantage2 from './advantage2@2x.png'
import advantage3 from './advantage3@2x.png'
import gateio from './logoGateIO2@2x.png'
import chainnews from './logoChainNews2@2x.png'
import advantageBg from './advantageBg@2x.png'
import advantageBgPhone from './advantageBgPhone@2x.png'

const Illustration = () => (
  <div className={styles.illustration}>
    <h2>
      <FormattedMessage id="productNotion" />
    </h2>
    <div className={styles.modules}>
      <div className={styles.module}>
        <img src={logo1} alt="" />
        <div>
          <h3>
            <FormattedMessage id="main.illustration.title1" />
          </h3>
          <p>
            <FormattedMessage id="main.illustration.desc1" />
          </p>
        </div>
      </div>
      <div className={styles.module}>
        <img src={logo2} alt="" />
        <div>
          <h3>
            <FormattedMessage id="main.illustration.title2" />
          </h3>
          <p>
            <FormattedMessage id="main.illustration.desc2" />
          </p>
        </div>
      </div>
      <div className={styles.module}>
        <img src={logo3} alt="" />
        <div>
          <h3>
            <FormattedMessage id="main.illustration.title3" />
          </h3>
          <p>
            <FormattedMessage id="main.illustration.desc3" />
          </p>
        </div>
      </div>
    </div>
    <div className={styles.platform}>
      <img className={styles.platformBg} src={advantageBg} />
      <img className={styles.platformBg + ' ' + styles.phone} src={advantageBgPhone} />
      <h2><FormattedMessage id="main.illustration.statementTitle" /></h2>
      <div className={styles.statements}>
        <div className={styles.statement}>
          <img src={advantage1} alt="" />
          <p>
            <FormattedMessage id="main.illustration.statement1" />
          </p>
        </div>

        <div className={styles.divided} />

        <div className={styles.statement + ' ' + styles.statement2}>
          <p>
            <FormattedMessage id="main.illustration.statement2" />
          </p>
          <img src={advantage2} alt="" />
        </div>

        <div className={styles.divided} />

        <div className={styles.statement}>
          <img src={advantage3} alt="" />
          <p>
            <FormattedMessage id="main.illustration.statement3" />
          </p>
        </div>
      </div>

      <div className={styles.data}>
        <p>
          <span className={styles.number}>1,000,000+  &nbsp;&nbsp;</span>
          <FormattedMessage id="main.illustration.data1" />
        </p>
        <p>
          <span className={styles.number}>100+  &nbsp;&nbsp;</span>
          <FormattedMessage id="main.illustration.data2" />
        </p>
        <p>
          <span className={styles.number}>2.85%  &nbsp;&nbsp;</span>
          <FormattedMessage id="main.illustration.data3" />
          <span style={{ color: '#8D8D8D' }}><FormattedMessage style={{ color: '#8D8D8D' }} id="main.illustration.data3comment" /></span>
        </p>
      </div>
    </div>
    <div className={styles.partners}>
      <div className={styles.partner}>
        <h2>
          <FormattedMessage id="advertiser" />
        </h2>
        <div className={styles.logoImgBg + ' ' + styles.advertiser} />
        <div className={styles.testimony}>

          <img src={gateio} alt="" />
          <p>
            <FormattedMessage id="main.illustration.testimony1" />
          </p>
        </div>
        <a href="http://atlaspio.mikecrm.com/q3rTS59" target="blank">
          <button className={styles.applyToTry}>
            <FormattedMessage id="applyForTrial" />
          </button>
        </a>
      </div>

      <div className={styles.partner}>
        <h2>
          <FormattedMessage id="publisher" />
        </h2>
        <div className={styles.logoImgBg + ' ' + styles.publisher} />
        <div className={styles.testimony}>
          <img src={chainnews} alt="" />
          <p>
            <FormattedMessage id="main.illustration.testimony2" />
          </p>
        </div>
        <a href="http://atlaspio.mikecrm.com/FyDjgk0" target="blank">
          <button className={styles.applyToJoin}>
            <FormattedMessage id="joinNow" />
          </button>
        </a>
      </div>
    </div>
  </div>
)

export default injectIntl(Illustration)
