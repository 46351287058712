import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import kr from './Logo36Kr@2x.png'
import bloomberg from './LogoBloomberg@2x.png'
import ccn from './LogoCCN@2x.png'
import coindesk from './LogoCoindesk@2x.png'
import telegraph from './LogoCoinTelegraph@2x.png'
import econoTimes from './LogoEconotimes@2x.png'
import naver from './LogoNaver@2x.png'
import sohu from './LogoSohu@2x.png'
import tencent from './LogoTencent@2x.png'
import yahoo from './LogoYahoo@2x.png'
import crypto from './logoCryptoCoinSpy@2x.png'
import bitcoinist from './logoBitcoinist@2x.png'

const Media = () => (
  <div className={styles.media}>
    <h1 className={styles.title}>
      <FormattedMessage id="media" />
    </h1>
    <div className={styles.logos}>
      <div className={styles.logo}>
        <a
          href="https://finance.yahoo.com/news/atlas-protocol-joins-iab-tech-043000261.html"
          target="blank"
        >
          <img src={yahoo} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://www.bloomberg.com/press-releases/2018-11-22/atlas-protocol-joins-iab-tech-lab-blockchain-working-group"
          target="blank"
        >
          <img src={bloomberg} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="http://it.sohu.com/20180821/n547037837.shtml" target="blank">
          <img src={sohu} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://finance.qq.com/a/20180821/039813.htm" target="blank">
          <img src={tencent} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://36kr.com/p/5149204.html" target="blank">
          <img src={kr} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://www.coindesk.com/softbank-baidu-lead-3-million-funding-for-ex-googlers-crypto-startup/"
          target="blank"
        >
          <img src={coindesk} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://cointelegraph.com/news/blockchain-startup-closes-multi-million-dollar-funding-round-led-by-softbank-baidu"
          target="blank"
        >
          <img src={telegraph} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://www.econotimes.com/Blockchain-marketing-startup-Atlas-Protocol-raises-3M-in-new-funding-led-by-Softbank-China-Capital-1418265"
          target="blank"
        >
          <img src={econoTimes} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://www.ccn.com/atlas-protocol-secures-multi-million-investment-led-by-softbank-china-capital-defining-blockchain-interactive-advertising/"
          target="blank"
        >
          <img src={ccn} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://m.news.naver.com/read.nhn?mode=LSD&sid1=101&oid=011&aid=0003375550"
          target="blank"
        >
          <img src={naver} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a
          href="https://cryptocoinspy.com/atlas-protocol-joins-amazons-aws-partner-network-apn-in-first-for-blockchain-adtech/"
          target="blank"
        >
          <img src={crypto} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://bitcoinist.com/former-google-employees-atlas-protocol/" target="blank">
          <img src={bitcoinist} alt="" />
        </a>
      </div>
    </div>
  </div>
)

export default Media
