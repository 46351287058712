import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import LocalizedLink from '../LocalizedLink'
import logo from './ATPLogo.png'
import styles from './index.module.css'

const Footer = ({ intl: { locale } }) => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.left}>
        <LocalizedLink to="/">
          <img src={logo} />
        </LocalizedLink>
        <ul>
          <li>
            <LocalizedLink to="/product" style={{ color: '#fff' }}>
              <FormattedMessage id="productModule" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to="/solution" style={{ color: '#fff' }}>
              <FormattedMessage id="solution" />
            </LocalizedLink>
          </li>
          <li>
            {locale === 'en' ? (
              <a
                style={{ color: '#fff' }}
                href="https://docs.google.com/forms/d/e/1FAIpQLSfGD8b0hneADbfIp8OhQzO1dJaMJOe1vqMlS2NY7f9cmqGJAg/viewform?usp=sf_link"
                target="blank"
              >
                FREE TRIAL
              </a>
            ) : (
              <a
                style={{ color: '#fff' }}
                href="http://atlaspio.mikecrm.com/0uJEmbE"
                target="blank"
              >
                申请试用
              </a>
            )}
          </li>
        </ul>
        <ul>
          <li>
            <LocalizedLink to="/about" style={{ color: '#fff' }}>
              <FormattedMessage id="aboutUs" />
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to="/join" style={{ color: '#fff' }}>
              <FormattedMessage id="joinTeam" />
            </LocalizedLink>
          </li>
        </ul>
      </div>

      <span className={styles.copyright}>Copyright © 2019 Atlaspro.io</span>
    </div>
  </div>
)

export default injectIntl(Footer)
