import React from 'react'
import { injectIntl } from 'react-intl'
import styles from './index.module.css'
import myPoint from './my@2x.png'
import myPointEn from './my_en@2x.png'

const MyPoint = ({ intl: { locale } }) => (
  <div className={styles.myPoint}>
    <a href="https://my.atlaspro.io" target="blank">
      {
        locale == 'zh' ? <img src={myPoint} /> : <img src={myPointEn} />
      }
    </a>
  </div>
)

export default injectIntl(MyPoint);
