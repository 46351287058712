import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.css'
import aws from './LogoAWS@2x.png'
import bv from './LogoBV@2x.png'
import bvp from './LogoBVP@2x.png'
import dhvc from './LogoDHVC@2x.png'
import feng from './LogoFenbushi@2x.png'
import iab from './LogoIAB@2x.png'
import nebulas from './LogoNebulabs@2x.png'
import onebot from './LogoOneBoat@2x.png'
import nas from './LogoNAS@2x.png'
import xgba from './LogoXGBA@2x.png'
import consensus from './logoConsensusLab@2x.png'
import bitsclub from './logoBitsclub@2x.png'

const Institution = () => (
  <div className={styles.institution}>
    <h1 className={styles.title}>
      <FormattedMessage id="institution" />
    </h1>
    <div className={styles.logos}>
      <div className={styles.logo}>
        <a href="httpo://nebulas.io/" target="blank">
          <img src={nas} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://bv.ai/" target="blank">
          <img src={bv} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="http://fenbushi.vc/" target="blank">
          <img src={feng} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://dh.vc/" target="blank">
          <img src={dhvc} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="http://www.oneboat.capital/" target="blank">
          <img src={onebot} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://www.huoxing24.com/" target="blank">
          <img src={consensus} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://labs.nebulas.io/" target="blank">
          <img src={nebulas} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://xgba.io/" target="blank">
          <img src={xgba} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="http://www.bitsclub.io/" target="blank">
          <img src={bitsclub} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://bitsclubvp.io/" target="blank">
          <img src={bvp} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://iabtechlab.com/" target="blank">
          <img src={iab} alt="" />
        </a>
      </div>
      <div className={styles.logo}>
        <a href="https://aws.amazon.com/cn/partners/" target="blank">
          <img src={aws} alt="" />
        </a>
      </div>
    </div>
  </div>
)

export default Institution
